class SliderProductCategoryWidgetHandler extends elementorModules.frontend
    .handlers.Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector(
            '.slider-product-category__list'
        )

        if (currentEle && sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 4,
                spaceBetween: 12,
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                        grid: {
                            fill: 'row',
                            rows: 4,
                        },
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 12,
                        grid: {
                            fill: 'column',
                            rows: 1,
                        },
                    },
                },
                navigation: {
                    prevEl: currentEle.querySelector(
                        '.slider-product-category__header .slider-navigation .prev button'
                    ),
                    nextEl: currentEle.querySelector(
                        '.slider-product-category__header .slider-navigation .next button'
                    ),
                },
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_slider_product_category',
        SliderProductCategoryWidgetHandler
    )
})
